import { mediaQueries } from 'shared/breakpoints';
import { css, DefaultTheme } from 'styled-components';
import { SPACING_PX } from 'styles/theme';

export interface ISpacingProps {
    // mobile
    isMobile?: boolean;
    // paddings
    p?: number;
    pb?: number;
    pl?: number;
    px?: number;
    py?: number;
    pr?: number;
    pt?: number;

    // margins
    m?: number;
    mt?: number;
    mr?: number;
    mb?: number;
    ml?: number;
    mx?: number;
    my?: number;
}

export interface ITypographyProps extends ISpacingProps {
    secondary?: boolean;
    tertiary?: boolean;
    bold?: boolean;
    semiBold?: boolean;
    onClick?: () => void;
    theme: DefaultTheme;
    align?: 'center' | 'left' | 'right';
    fontStyle?: 'italic';
    decoration?: 'underline';
    textWrap?: 'nowrap' | 'wrap';
    transform?: 'uppercase' | 'lowercase';
    maxW?: string;
}

export const spacingProps = (p: ISpacingProps) => css`
    ${p.m && `margin: ${p.m * SPACING_PX}px`};
    ${p.mt && `margin-top: ${p.mt * SPACING_PX}px`};
    ${p.mr && `margin-right: ${p.mr * SPACING_PX}px`};
    ${p.mb && `margin-bottom: ${p.mb * SPACING_PX}px`};
    ${p.ml && `margin-left: ${p.ml * SPACING_PX}px`};
    ${p.mx &&
    `padding-left: ${p.mx * SPACING_PX}px; padding-right: ${
        p.mx * SPACING_PX
    }px`};
    ${p.my &&
    `padding-top: ${p.my * SPACING_PX}px; padding-bottom: ${
        p.my * SPACING_PX
    }px`};

    ${p.pt && `padding-top: ${p.pt * SPACING_PX}px`};
    ${p.pr && `padding-right: ${p.pr * SPACING_PX}px`};
    ${p.pb && `padding-bottom: ${p.pb * SPACING_PX}px`};
    ${p.pl && `padding-left: ${p.pl * SPACING_PX}px`};
    ${p.p && `padding: ${p.p * SPACING_PX}px`};
    ${p.px &&
    `padding-left: ${p.px * SPACING_PX}px; padding-right: ${
        p.px * SPACING_PX
    }px`};
    ${p.py &&
    `padding-top: ${p.py * SPACING_PX}px; padding-bottom: ${
        p.py * SPACING_PX
    }px`};
`;

export interface ICustomStyledProps extends ISpacingProps {
    flex?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
    flexGrow?: number;
    overflow?: 'scroll' | 'hidden';
    flexDirection?: 'row-reverse' | 'column-reverse' | 'row';
    flexSpan?: number;
    flexWrap?: 'wrap' | 'none';
    fullyCentered?: boolean;
    justifyContent?: 'center' | 'space-between' | 'flex-start' | 'flex-end';
    alignItems?:
        | 'center'
        | 'baseline'
        | 'flex-start'
        | 'space-between'
        | 'flex-end';
    w?: string;
    h?: string;
    position?: 'relative' | 'absolute' | 'initial';
    zIndex?: number;
    gap?: string;
    onClick?: () => void;
    hidden?: boolean;
    maxW?: string;
    maxH?: string;
    minH?: string;
    minW?: string;
}

export const customProps = (p: ICustomStyledProps) => css`
    ${spacingProps};

    ${p.w && `width: ${p.w}`};
    ${p.minW && `min-width: ${p.minW}`};
    ${p.minH && `min-height: ${p.minH}`};
    ${p.maxW && `max-width: ${p.maxW}`};
    ${p.maxH && `max-height: ${p.maxH}`};
    ${p.h && `height: ${p.h}`};

    ${p.flex && `display: flex; flex-direction: ${p.flex};`};
    ${p.flexWrap && `flex-wrap: ${p.flexWrap};`};
    ${p.flexGrow && `flex-grow: ${p.flexGrow};`};
    ${p.flexSpan && `flex: ${p.flexSpan};`};
    ${p.fullyCentered &&
    `display: flex; justify-content: center; align-items: center; width: 100%; height: 100%;`};
    ${p.justifyContent &&
    `
    justify-content: ${p.justifyContent};
    -webkit-justify-content: ${p.justifyContent};
    `};
    ${p.flexDirection && `flex-direction: ${p.flexDirection};`};
    ${p.gap && `gap: ${p.gap};`};
    ${p.alignItems &&
    `
    align-items: ${p.alignItems};
    -webkit-align-items: ${p.alignItems};
    `};
    ${p.position && `position: ${p.position};`};
    ${p.zIndex && `z-index: ${p.zIndex};`};
    ${p.overflow && `overflow: ${p.overflow};`};
    ${p.onClick && 'cursor: pointer'};
    ${p.hidden && 'display: none;'};
    ${p.minH && `min-height: ${p.minH};`};
`;

export const commonPaddings = (headerMenuIsOpen?: boolean) => css`
    padding-left: 152px;
    padding-right: 148px;

    ${mediaQueries.md} {
        padding-left: 62px;
        padding-right: 62px;
    }

    ${mediaQueries.ipadV} {
        padding-right: ${headerMenuIsOpen ? '68px' : '62px'};
    }

    ${mediaQueries.xs} {
        padding-left: 28px;
        padding-right: ${headerMenuIsOpen ? '34px' : '28px'};
    }
`;

export const swiperNavigationStyles = () => css`
    .swiper-pagination-bullet {
        width: 5px;
        height: 5px;
    }

    .swiper-pagination-bullet-active {
        background: white;
        width: 7px;
        height: 7px;
    }
`;
