import { commonPaddings } from 'components/_universal/commonStyles';
import * as Common from 'src/components/_universal/Common';
import styled, { css } from 'styled-components';

export const HeaderWrapper = styled(Common.Div)<{
    headerMenuIsOpen?: boolean;
}>(
    ({ headerMenuIsOpen }) => css`
        position: absolute;
        top: 0;
        height: 86px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        ${commonPaddings(headerMenuIsOpen)};
    `,
);

export const IconLine = styled.div`
    height: 4px;
    transform-origin: 4.5px;
    transition: all 0.3s linear;
    width: 40px;
`;

export const BurgerButton = styled(Common.Div)<{ isOpen: boolean }>(
    ({ theme: { colors }, isOpen }) => css`
        align-items: flex-end;
        background: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        padding: 0;
        pointer-events: all;
        z-index: 999;

        &:focus {
            outline: none;
        }

        ${IconLine} {
            background: ${colors.white};

            &:first-child {
                transform: ${isOpen ? 'rotate(45deg)' : 'rotate(0)'};
            }

            &:nth-child(2) {
                transform: ${isOpen ? 'rotate(-45deg)' : 'rotate(20)'};
                margin-top: 18px;
            }
        }
    `,
);
