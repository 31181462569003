import { commonPaddings } from 'components/_universal/commonStyles';
import { B2, B3, B4 } from 'components/_universal/Typography.styled';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { mediaQueries } from 'shared/breakpoints';
import { ID_ABOUT_KIOSK, ID_BENEFITS, ID_FOR_WHOM } from 'shared/constants';
import * as Common from 'src/components/_universal/Common';
import styled, { css } from 'styled-components';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import picLogoFooter from 'assets/images/pictures/picLogoFooter.png';
import { scrollToElement } from 'utils/scrollToElement';

const FooterWrapper = styled(Common.Div)(
    ({ theme: { colors } }) => css`
        width: 100%;
        background: ${colors.footer};
        padding-top: 80px;
        padding-bottom: 24px;
        ${commonPaddings()};

        ${mediaQueries.xs} {
            padding-top: 32px;
        }

        ${mediaQueries.ipadV} {
            padding-top: 64px;
        }
    `,
);

const CopyrightSeparator = styled(Common.Div)(
    ({ theme: { colors } }) => css`
        width: 100%;
        background: ${colors.white};
        height: 1px;
        margin-bottom: 20px;
    `,
);

// TODO: FAQ + PrivacyPolicy
export const Footer = () => {
    const isMobile = useBreakpoint('sm');
    const isDesktop = useBreakpoint('lg');
    const isPad = useBreakpoint('ipadH');
    const { t } = useTranslation();

    return (
        <FooterWrapper>
            <Common.Svg src={picLogoFooter} mb={isMobile ? 2 : 3.5} />
            <Common.Div
                flex={isMobile ? 'column' : 'row'}
                gap="70px"
                justifyContent={isDesktop ? 'space-between' : 'left'}
                mb={isDesktop ? 0 : 7}
            >
                <B4 secondary maxW="330px">
                    <Trans i18nKey="footer.appDesc" />
                </B4>
                <Common.Div
                    flex={isMobile ? 'column' : 'row'}
                    gap={isPad ? '60px' : '140px'}
                    justifyContent={isMobile ? 'left' : 'space-between'}
                    mb={isMobile ? 4.5 : 9}
                >
                    <Common.Div
                        flex={'row'}
                        justifyContent={isMobile ? 'left' : 'space-between'}
                        gap={isPad ? '60px' : '140px'}
                    >
                        <Common.Div flex="column" gap="8px">
                            <B3 bold tertiary mb={2}>
                                {t('footer.about_title')}
                            </B3>
                            <B2
                                tertiary
                                onClick={() => scrollToElement(ID_FOR_WHOM)}
                            >
                                {t('footer.about_item_1')}
                            </B2>
                            <B2
                                tertiary
                                onClick={() => scrollToElement(ID_ABOUT_KIOSK)}
                            >
                                {t('footer.about_item_2')}
                            </B2>
                            <B2
                                tertiary
                                onClick={() => scrollToElement(ID_BENEFITS)}
                            >
                                {t('footer.about_item_3')}
                            </B2>
                        </Common.Div>
                        <Common.Div flex="column" gap="8px">
                            <B3 bold tertiary mb={2}>
                                {t('footer.help_title')}
                            </B3>
                            <B2 tertiary>{t('footer.help_item_1')}</B2>
                            <B2 tertiary>{t('footer.help_item_2')}</B2>
                        </Common.Div>
                    </Common.Div>
                    <Common.Div flex="column" gap="8px">
                        <B3 bold tertiary mb={2}>
                            {t('footer.sales_title')}
                        </B3>
                        <B2 tertiary>{t('footer.sales_phone')}</B2>
                        <B2 tertiary>{t('footer.sales_email')}</B2>
                    </Common.Div>
                </Common.Div>
            </Common.Div>
            <CopyrightSeparator />
            <Common.Div
                flex={isMobile ? 'column' : 'row'}
                justifyContent={isMobile ? 'left' : 'space-between'}
                alignItems={isMobile ? 'center' : 'left'}
            >
                <B4 secondary>{t('footer.copyright')}</B4>
                <B4 secondary>{t('footer.company_address')}</B4>
            </Common.Div>
        </FooterWrapper>
    );
};
