import CompanyLogo from 'assets/images/pictures/picLogoHeader.png';
import { HeaderWrapper } from 'components/_layout/Header/Header.styled';
import { MenuActions } from 'components/_layout/Header/MenuActions';
import { SideMenu } from 'components/_layout/Header/SideMenu';
import React, { useEffect } from 'react';
import * as Common from 'src/components/_universal/Common';
import { useTheme } from 'src/layouts/ThemeContext';

export const Header = () => {
    const { headerMenuIsOpen, setHeaderMenuIsOpen } = useTheme();

    const handleScroll = () => {
        setHeaderMenuIsOpen(false);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    return (
        <>
            <HeaderWrapper headerMenuIsOpen={headerMenuIsOpen}>
                <Common.Svg src={CompanyLogo} w="72px" h="20px" />
                <MenuActions />
            </HeaderWrapper>
            <SideMenu />
        </>
    );
};
