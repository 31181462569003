import {
    BurgerButton,
    IconLine,
} from 'components/_layout/Header/Header.styled';
import LanguageSwitch from 'components/_universal/LanguageSwitch';
import React from 'react';
import { CLASSNAME_PREVENT_CLOSE_OUTSIDE } from 'shared/constants';
import * as Common from 'src/components/_universal/Common';
import { useTheme } from 'src/layouts/ThemeContext';

export const MenuActions = () => {
    const { headerMenuIsOpen, setHeaderMenuIsOpen } = useTheme();

    const handleSideMenuOpen = () => {
        setHeaderMenuIsOpen(!headerMenuIsOpen);
    };

    return (
        <Common.Div
            flex="row"
            gap="24px"
            zIndex={999}
            alignItems="center"
            className={CLASSNAME_PREVENT_CLOSE_OUTSIDE}
        >
            <LanguageSwitch className={CLASSNAME_PREVENT_CLOSE_OUTSIDE} />
            <BurgerButton
                onClick={handleSideMenuOpen}
                isOpen={headerMenuIsOpen}
                className={CLASSNAME_PREVENT_CLOSE_OUTSIDE}
            >
                <IconLine className={CLASSNAME_PREVENT_CLOSE_OUTSIDE} />
                <IconLine className={CLASSNAME_PREVENT_CLOSE_OUTSIDE} />
            </BurgerButton>
        </Common.Div>
    );
};
