import { createContext, useContext } from 'react';

export interface ThemeContextType {
    headerMenuIsOpen: boolean;
    setHeaderMenuIsOpen: (headerMenuIsOpen: boolean) => void;
    isScrollDisabled: boolean;
    setIsScrollDisabled: (headerMenuIsOpen: boolean) => void;
    isContactOpened: boolean;
    setIsContactOpened: (contactIsOpened: boolean) => void;
}

export const ThemeContext = createContext<ThemeContextType>({
    headerMenuIsOpen: false,
    isScrollDisabled: false,
    isContactOpened: false,
    setIsScrollDisabled: () => undefined,
    setHeaderMenuIsOpen: () => undefined,
    setIsContactOpened: () => undefined,
});

export const useTheme = () => useContext(ThemeContext);
