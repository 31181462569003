import { Footer } from 'components/_layout/Footer/Footer';
import Head from 'components/_layout/Head';
import { Header } from 'components/_layout/Header/Header';
import i18next from 'i18next';
import i18n from 'i18next';
import translationEn from 'locales/en_translation.json';
import translationPl from 'locales/pl_translation.json';
import React, { ReactNode, useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { initReactI18next } from 'react-i18next/initReactI18next';
import { mediaQueries } from 'shared/breakpoints';
import { ROUTE_TO_PAGE_NAME_MAP } from 'shared/paths';

import { ThemeContext } from 'src/layouts/ThemeContext';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyles from 'styles/GlobalStyles.styled';

import { Theme } from 'types/CommonTypes';
import themeVariant from 'utils/themeVariant';

interface ILayoutProps {
    children: ReactNode;
    pageTitle?: string;
    location: {
        pathname: string;
    };
    className?: string;
}

const scrollUp = () => {
    scrollTo({
        behavior: 'smooth',
        top: 0,
    });
};

const Wrapper = styled.div``;

const ScrollUpArrow = styled.div`
    bottom: 40px;
    cursor: pointer;
    height: 40px;
    position: fixed;
    right: 40px;
    z-index: 99999;

    ${mediaQueries.xs} {
        bottom: 20px;
        right: 20px;
    }
`;

const resources = {
    en: {
        translation: translationEn,
    },
    pl: {
        translation: translationPl,
    },
};

export default ({ children, className }: ILayoutProps) => {
    const [headerMenuIsOpen, setHeaderMenuIsOpen] = useState(false);
    const [isScrollDisabled, setIsScrollDisabled] = useState(false);
    const [isContactOpened, setIsContactOpened] = useState(false);

    //const atTopPage = prevScrollPos === 0;

    const location =
        typeof window !== 'undefined'
            ? window.location
            : { pathname: 'Codahead' };
    const pageTitle = ROUTE_TO_PAGE_NAME_MAP[location.pathname];

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const handleScroll = () => {
        // if (!headerMenuIsOpen) {
        //     const currentScrollPos = window.pageYOffset;
        //
        //     setHeaderIsVisible(
        //         (prevScrollPos > currentScrollPos &&
        //             prevScrollPos - currentScrollPos > 0) ||
        //             currentScrollPos < 10,
        //     );
        //
        //     setPrevScrollPos(currentScrollPos);
        // }
    };

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
    //
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [prevScrollPos, headerIsVisible, handleScroll]);

    // TODO: Remove those errors
    i18next.init({
        interpolation: { escapeValue: false }, // React already does escaping
    });

    i18n.use(initReactI18next).init({
        resources,
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

    return (
        <div className={className}>
            <ThemeContext.Provider
                value={{
                    headerMenuIsOpen,
                    setHeaderMenuIsOpen,
                    isScrollDisabled,
                    setIsScrollDisabled,
                    isContactOpened,
                    setIsContactOpened,
                }}
            >
                <GlobalStyles
                    headerMenuIsOpen={headerMenuIsOpen}
                    isScrollDisabled={isScrollDisabled}
                />
                <Head title={pageTitle} pathname={location?.pathname} />
                <ThemeProvider theme={themeVariant[Theme.DEFAULT]}>
                    {/*<CookiesLaw />*/}
                    {/*<TalkProjectPopup />*/}
                    <Header />
                    {/*{!headerMenuIsOpen && !atTopPage && (*/}
                    {/*    <ScrollUpArrow onClick={() => scrollUp()}>*/}
                    {/*        <Common.Svg src={ScrollUpArrowIcon} />*/}
                    {/*    </ScrollUpArrow>*/}
                    {/*)}*/}
                    {/*<ToastNotification />*/}
                    <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
                    <Footer />
                </ThemeProvider>
            </ThemeContext.Provider>
        </div>
    );
};
