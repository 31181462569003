export const SPACING_PX = 8;

const defaultTheme = {
    colors: {
        black: '#000000',
        error: ' #C52323',
        white: '#FFFFFF',

        mainWhite: '#FFFFFF',
        mainGolden: '#A4861E',
        buttonBg: '#cdcdcd',
        buttonText: '#545454',
        text: '#292D32',
        background: '#FFFFFF',
        backgroundSection: '#F8F8F8',
    },
    gradients: {
        sideMenu: 'linear-gradient(200deg, #292D32 -0.04%, #434547 120.73%)',
        footer: 'linear-gradient(261deg, #292D32 26.01%, #434547 108.28%)',
        modal: 'linear-gradient(261deg, #292D32 26.01%, #434547 108.28%)',
    },
    borderRadius: {
        primary: '40px',
        secondary: '30px',
    },
    fontSizes: {
        h1: '80px',
        h2: '36px',
        h3: '32px',
        h4: '24px',
        b1: '20px',
        b2: '16px',
        b3: '14px',
        b4: '12px',
    },
    fontWeight: {
        light: 300,
        regular: 400,
        medium: 500,
        semiBold: 600,
        bold: 700,
    },
    fontFamily: {
        primary: `Montserrat`,
        secondary: `Montserrat`,
        bold: 'MontserratBold',

        // new fonts
        heading: 'Montserrat',
        body: 'Montserrat',
    },
    spacing: (n: number) => `${n * SPACING_PX}px`,
};

export default defaultTheme;
