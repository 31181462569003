import {
    OverlayMask,
    Separator,
    SideMenuContainer,
    TextMenuLink,
} from 'components/_layout/Header/SideMenu.styled';
import { B3 } from 'components/_universal/Typography.styled';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
    CLASSNAME_PREVENT_CLOSE_OUTSIDE,
    ID_ABOUT_KIOSK,
    ID_BENEFITS,
    ID_CODAFACE,
    ID_FOR_WHOM,
} from 'shared/constants';
import * as Common from 'src/components/_universal/Common';
import { useTheme } from 'src/layouts/ThemeContext';
import { useOnClickOutside } from 'utils/hooks/useClickOutside';
import { scrollToElement } from 'utils/scrollToElement';

export const SideMenu = () => {
    const { headerMenuIsOpen, setHeaderMenuIsOpen } = useTheme();
    const { setIsContactOpened } = useTheme();
    const sideMenuRef = useRef(null);

    useOnClickOutside(sideMenuRef, (e: MouseEvent) => {
        const { target } = e;
        if (target instanceof HTMLElement) {
            const clickedClass = target.className ?? '';

            // NOTE: MenuActions is floating above sideMenu, so we need to check if we does not click it
            if (!clickedClass.includes(CLASSNAME_PREVENT_CLOSE_OUTSIDE))
                setHeaderMenuIsOpen(false);
        }
    });

    const handleMenuItemClick = (elementName: string) => {
        scrollToElement(elementName);
        setHeaderMenuIsOpen(false);
    };

    const handleContactClick = () => {
        setIsContactOpened(true);
        setHeaderMenuIsOpen(false);
    };

    const { t } = useTranslation();

    // TODO: Connect /contact page
    return (
        <>
            <SideMenuContainer isVisible={headerMenuIsOpen} ref={sideMenuRef}>
                <Common.Div flex="column" gap="36px">
                    <TextMenuLink
                        onClick={() => handleMenuItemClick(ID_FOR_WHOM)}
                    >
                        {t('sideMenu.for_whom')}
                    </TextMenuLink>
                    <TextMenuLink
                        onClick={() => handleMenuItemClick(ID_ABOUT_KIOSK)}
                    >
                        {t('sideMenu.about_kiosk')}
                    </TextMenuLink>
                    <TextMenuLink
                        onClick={() => handleMenuItemClick(ID_BENEFITS)}
                    >
                        {t('sideMenu.benefits')}
                    </TextMenuLink>
                    <TextMenuLink onClick={() => handleContactClick()}>
                        {t('sideMenu.contact')}
                    </TextMenuLink>
                </Common.Div>
                <Separator />
                <B3 tertiary bold transform="uppercase" mb={3}>
                    {t('sideMenu.more_title')}
                </B3>
                <Common.Div flex="column">
                    <B3 tertiary>{t('sideMenu.more_faq')}</B3>
                    <B3 tertiary>{t('sideMenu.more_pp')}</B3>
                    <B3
                        tertiary
                        onClick={() => handleMenuItemClick(ID_CODAFACE)}
                    >
                        {t('sideMenu.more_codaface')}
                    </B3>
                </Common.Div>
            </SideMenuContainer>
            {headerMenuIsOpen && <OverlayMask />}
        </>
    );
};
