import MontSerratFont from 'assets/fonts/Montserrat-VariableFont_wght.ttf';
import MontSerratBoldFont from 'assets/fonts/Montserrat-Bold.ttf';
import { mediaQueries } from 'shared/breakpoints';
import { createGlobalStyle, css } from 'styled-components';
import { normalize } from 'styled-normalize';

export default createGlobalStyle<{
    headerMenuIsOpen?: boolean;
    isScrollDisabled?: boolean;
}>`
  ${normalize}
  
  @font-face {
    font-family: 'Montserrat';
    src: url(${MontSerratFont}) format('truetype'),
    url(${MontSerratFont}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'MontserratBold';
    src: url(${MontSerratBoldFont}) format('truetype'),
    url(${MontSerratBoldFont}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  ${({ headerMenuIsOpen, isScrollDisabled }) => css`
      html {
          box-sizing: border-box;
          scroll-behavior: smooth;
      }
      body {
          color: white;
          -webkit-font-smoothing: antialiased;
          font-family: 'Montserrat', 'Work Sans', sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.25;
          min-height: 100vh;
          margin: 0;
          overflow-x: hidden;
          background: #2f2f2f;
          width: 100%;
          ${isScrollDisabled && 'overflow-y: hidden'};

          ${mediaQueries.ipadV} {
              // exclude scrollbar width when opening side menu
              width: ${headerMenuIsOpen ? 'calc(100% - 6px)' : '100%'};

              // stop page scrolling when side menu is opened and is in fullscreen
              ${headerMenuIsOpen && 'overflow-y: hidden'};
          }
      }
  `}
  
  * {
    box-sizing: border-box;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  
  p {
    margin: 0;
  }
  
  input, textarea {
    border-radius: 0;
  }
  
  strong {
    font-weight: 700;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  
  #root {
    height: 100%;
  }
  
  svg {
    pointer-events: none;
  }

  a {
    text-decoration: none;
    color: #3DA2EB;
  }
  
  p, a {
    outline: 0;
  }
  
  .svg-icon{
    width: 1em;
    height: 1em;
  }
  
  ::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background: gray; 
    border-radius: 3px;
  }

  // background overlay for Zoom component when opening images
  [data-rmiz-modal-overlay] {
    background: #2F2F2F;
  }
`;
