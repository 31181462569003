import {
    ITypographyProps,
    spacingProps,
} from 'src/components/_universal/commonStyles';
import { mediaQueries } from 'shared/breakpoints';

import styled, { css } from 'styled-components';

const commonTypographyStyles = (p: ITypographyProps) => css`
    ${p.align && `justify-content: ${p.align}`};
    margin: 0;
    padding: 0;
    // NOTE: enables line breaks in translation
    white-space: pre-line;

    ${p.onClick &&
    css`
        cursor: pointer;
        &:hover {
            color: ${p.theme.colors.white};
        }
    `};
    ${p.transform && `text-transform: ${p.transform}`};
    ${p.fontStyle && `font-style: ${p.fontStyle}`};
    ${p.decoration &&
    `text-decoration: ${p.decoration};
    `};
    color: ${() => {
        if (p.secondary) return p.theme.colors.white;
        if (p.tertiary) return p.theme.colors.mainGolden;
        return p.theme.colors.text;
    }};
    ${p.textWrap && `text-wrap: ${p.textWrap}`};
    ${p.maxW && `max-width: ${p.maxW}`};
    ${p.bold && `font-family: ${p.theme.fontFamily.bold}`};
    ${p.semiBold && `font-weight: 600}`};
`;

export const H1 = styled.h1(
    ({ theme: { fontSizes, fontWeight, fontFamily } }) => css`
        font-family: ${fontFamily.bold};
        font-size: ${fontSizes.h1};
        font-weight: ${fontWeight.regular};
        line-height: 80px;
        ${commonTypographyStyles};
        ${spacingProps};

        ${mediaQueries.xs} {
            font-size: 45px;
            line-height: 50px;
        }
    `,
);

export const H2 = styled.h2(
    ({ theme: { fontSizes, fontWeight, fontFamily } }) => css`
        font-family: ${fontFamily.heading};
        display: flex;
        font-size: ${fontSizes.h2};
        font-weight: ${fontWeight.regular};
        ${commonTypographyStyles};
        ${spacingProps};
    `,
);

export const H3 = styled.h3(
    ({ theme: { fontWeight, fontFamily } }) => css`
        font-size: 34px;
        font-weight: ${fontWeight.light};
        font-family: ${fontFamily.heading};

        ${commonTypographyStyles};
        ${spacingProps};

        ${mediaQueries.xs} {
            font-size: 20px;
        }
    `,
);

export const B0 = styled.p(
    ({ theme: { fontSizes, fontWeight } }) => css`
        font-size: ${fontSizes.b0};
        font-weight: ${fontWeight.regular};
        line-height: 35px;
        ${commonTypographyStyles};
        ${spacingProps};
    `,
);

export const B1 = styled.p(
    ({ theme: { fontSizes, fontWeight } }) => css`
        font-size: ${fontSizes.b1};
        font-weight: ${fontWeight.light};
        line-height: 35px;
        ${commonTypographyStyles};
        ${spacingProps};
    `,
);

export const B2 = styled.p(
    ({ theme: { fontSizes, fontWeight } }) => css`
        font-size: ${fontSizes.b2};
        line-height: 25px;
        font-weight: ${fontWeight.regular};
        ${commonTypographyStyles};
        ${spacingProps};
    `,
);

export const B3 = styled.p(
    ({ theme: { fontSizes, fontWeight } }) => css`
        font-size: ${fontSizes.b3};
        font-weight: ${fontWeight.light};
        line-height: 25px;
        ${commonTypographyStyles};
        ${spacingProps};
    `,
);

export const B4 = styled.p(
    ({ theme: { fontSizes, fontWeight } }) => css`
        font-size: ${fontSizes.b4};
        font-weight: ${fontWeight.light};
        line-height: 20px;
        ${commonTypographyStyles};
        ${spacingProps};
    `,
);

export const H4 = styled.h4(
    ({ theme: { fontSizes, fontWeight } }) => css`
        font-size: ${fontSizes.h4};
        font-weight: ${fontWeight.light};
        line-height: 35px;
        ${commonTypographyStyles};
        ${spacingProps};
    `,
);

export const SectionText = styled.p(
    ({ theme: { colors, fontFamily, fontSizes, fontWeight } }) => css`
        color: ${colors.white};
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f14};
        font-weight: ${fontWeight.regular};
        line-height: 25px;
        ${commonTypographyStyles};
        ${spacingProps};

        ${mediaQueries.sm} {
            font-size: ${fontSizes.f14};
        }
    `,
);

export const Text = styled.p(
    ({ theme: { colors, fontFamily, fontSizes, fontWeight } }) => css`
        color: ${colors.mainBlue};
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f14};
        font-weight: ${fontWeight.regular};
        line-height: 22px;

        ${mediaQueries.sm} {
            font-size: ${fontSizes.f12};
        }
    `,
);

export const sharedButtonStyles = css(
    ({ theme: { fontSizes, fontWeight } }) => css`
        align-items: center;
        border: none;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        font-size: ${fontSizes.f13};
        font-weight: ${fontWeight.regular};
        justify-content: center;
        line-height: 13px;
        padding: 17px 43px;
        text-align: center;
        text-decoration: none;
        white-space: nowrap;
        width: fit-content;
        height: fit-content;
    `,
);

export const StyledExternalLink = styled.a(
    ({ theme: { colors } }) => css`
        p {
            align-items: center;
            display: flex;
            text-decoration: none;
            color: white;
            cursor: pointer;

            &:visited {
                text-decoration: none;
            }

            &:hover {
                color: ${colors.hoverBlue};
            }
        }
    `,
);
