import { B3 } from 'components/_universal/Typography.styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CLASSNAME_PREVENT_CLOSE_OUTSIDE } from 'shared/constants';

const LanguageSwitch = () => {
    const { i18n } = useTranslation();
    const lang = i18n.language;

    const handleLanguageChange = () => {
        if (lang === 'en') i18n.changeLanguage('pl');
        else i18n.changeLanguage('en');
    };

    return (
        <B3
            onClick={handleLanguageChange}
            secondary
            bold
            className={CLASSNAME_PREVENT_CLOSE_OUTSIDE}
        >
            {lang === 'en' ? 'EN' : 'PL'}
        </B3>
    );
};

export default LanguageSwitch;
