import { B2 } from 'components/_universal/Typography.styled';
import React from 'react';
import { mediaQueries } from 'shared/breakpoints';
import * as Common from 'src/components/_universal/Common';
import styled, { css } from 'styled-components';

export const SideMenuContainer = styled(Common.Div)<{
    isVisible?: boolean;
}>(
    ({ isVisible, theme: { gradients } }) => css`
        display: ${isVisible ? 'flex' : 'none'};
        position: fixed;
        flex-direction: column;
        top: 0;
        right: 0;
        height: 100vh;
        width: 420px;
        background: ${gradients.sideMenu};
        padding: 175px 54px 0 54px;
        z-index: 99;

        ${mediaQueries.md} {
            padding-left: 62px;
        }

        ${mediaQueries.xs} {
            padding-left: 32px;
        }

        ${mediaQueries.ipadV} {
            width: 100%;
            left: 0;
        }
    `,
);

export const TextMenuLink = styled(B2)(
    ({ theme }) => css`
        text-transform: uppercase;
        font-family: ${theme.fontFamily.bold};
        color: ${theme.colors.mainWhite};
        cursor: pointer;

        &:hover {
            color: ${theme.colors.mainGolden};
        }
    `,
);

export const Separator = styled.div(
    ({ theme }) => css`
        width: min(217px, 100%);
        background: ${theme.colors.white};
        height: 1px;
        margin-top: 40px;
        margin-bottom: 80px;
    `,
);

export const OverlayMask = styled(Common.Div)`
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.45);
    z-index: 9;
`;
